@import url('https://fonts.googleapis.com/css2?family=Source+Sans+3:wght@400;500;600;700&display=swap');

:root {
  --primary: #12372a;
  --primary_hover: #436850;
  --primary_hover1: #436850;
  --black: #070707;
  --black_light: #9e9e9e;
  --white: #fbfada;
  --gray: #404040;
  --padding-lg: 1.5rem 8rem;
  --padding-xl: 6.5rem 8rem 1rem;
  --padding-md: 6.5rem 4rem 1rem;
  --padding-md_nav: 1.5rem 4rem;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Source Sans 3', sans-serif;
  background: #12372a;
  background: -webkit-linear-gradient(rgb(231, 253, 244), rgb(190, 231, 195));
}

h1 {
  font-size: 1.5rem;
  font-weight: 700;
  margin: 0.5rem 0 1rem;
}

.dark_gradient {
  background: -webkit-linear-gradient(var(--black), var(--black_light));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.green_gradient {
  background: -webkit-linear-gradient(var(--primary), var(--primary_hover));
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.green_1 {
  color: rgb(2, 105, 2) !important;
  font-weight: bold;
}

.white_1 {
  color: white !important;
  font-weight: bold;
}

/* .green_1:hover {
  text-decoration: underline;
} */

.site-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.small-container {
  max-width: 500px;
}

.card-title {
  margin-bottom: var(--bs-card-title-spacer-y);
  color: var(--bs-card-title-color);
}

a {
  text-decoration: none;
}

.c_tit {
  color: var(--primary_hover);
  /* border-bottom: 2px solid var(--primary_hover); */
  display: inline-block;
}

.c_tit:hover {
  color: var(--primary);
  border-bottom: 2px solid var(--primary);
}

header {
  position: fixed;
  width: 100%;
  padding: 1.5rem 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--primary);
  background-image: linear-gradient(
    25deg,
    var(--primary_hover) 41%,
    var(--primary) 100%
  );
  gap: 20px;
  z-index: 99;
}

.m_top1 {
  margin-top: 0.5rem;
}

.m_top {
  margin-top: 2.5rem;
}

.m_bot {
  margin-bottom: 2.5rem;
}

.dis_none {
  display: none;
}

.div_none {
  display: none !important;
}

.lg_scr {
  display: none !important;
}

.buttonColor {
  background: #12372a !important;
  color: var(--white) !important;
  outline: none;
  border: none;
}

.buttonColor:hover {
  color: #12372a !important;
  background: var(--white) !important;
}

.buttonColor1 {
  color: #12372a !important;
  background: var(--white) !important;
  outline: none;
  border: none;
  margin-left: 10px;
}

.buttonColor1:hover {
  background: #12372a !important;
  color: var(--white) !important;
}

.btn-outline-primary {
  background-color: var(--primary_hover);
  color: var(--white);
}

.red {
  font-size: 13px;
  color: red;
  margin: 10px 0;
}

.red1 {
  font-size: 11px;
  color: red;
  margin: 10px 0;
}

.pos_tr {
  position: absolute;
  top: 0;
  right: 0;
}

.flash_Mes {
  position: absolute;
  top: -25px;
  right: 10px;
  margin-left: 5px;
  color: rgb(3, 124, 3);
  font-size: 13px;
  font-style: italic;
}

.btn-outline-primary:hover {
  background-color: var(--white);
  color: var(--primary_hover);
}

.search__btn:hover .search_icon {
  color: var(--primary_hover) !important;
}

.link_deco {
  text-decoration: none;
}

.link_deco:hover {
  text-decoration: underline !important;
}

.name_bold {
  font-weight: bold;
}

.th_lg {
  text-align: center;
}

.logo_cont {
  display: flex;
  align-items: center;
  justify-content: center;
  /* gap: 0.5rem; */
}

.logo_text {
  font-size: 1.25rem;
  font-weight: 600;
  margin: 0;
}

.sm_container {
  padding: 1rem 0;
}

.refer_info {
  max-width: 400px;
  margin: 0;
  padding: 0;
  font-size: 0.85rem;
  color: rgb(116, 116, 116);
}

.refer_head {
  margin: 0 !important;
  padding: 0 !important;
}

.space_btw {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.search_icon {
  color: var(--white);
}

header a {
  text-decoration: none;
  color: var(--white);
}

header i {
  font-size: 1.25rem;
}

.textGray {
  color: var(--gray);
  font-size: 1rem;
}

.cur_ptr {
  cursor: pointer;
}

.dis_flex1 {
  display: flex;
  /* justify-content: space-between; */
  align-items: center;
  gap: 5px;
}

.share_des {
  font-size: 14px;
  font-weight: bold;
}

.wa_green {
  background: var(--primary_hover);
}

.x_blue {
  background: #1da1f2;
}

.fb_blue {
  background: #4267b2;
}

.ref_icon_cont {
  border-radius: 50%;
  color: white;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.dis00 {
  display: none;
}

@keyframes flowRight {
  0% {
    transform: translateX(250px);
  }
  /* 25%  {background-color:yellow; left:200px; top:0px;} */
  /* 50% {
    transform: translateX(50px);
  } */
  /* 75%  {background-color:green; left:0px; top:200px;} */
  100% {
    transform: translateX(0);
  }
}

@keyframes fadeOff {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }
  /* 25%  {background-color:yellow; left:200px; top:0px;} */
  /* 50% {
    opacity: 0.5;
  } */
  /* 75%  {background-color:green; left:0px; top:200px;} */
  100% {
    opacity: 1;
  }
}

@keyframes dropIn {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  /* 25%  {background-color:yellow; left:200px; top:0px;} */
  /* 50% {
    opacity: 0.5;
    transform: translateY(-10px);
  } */
  /* 75%  {background-color:green; left:0px; top:200px;} */
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.p_fadeOff {
  animation-name: fadeOff;
  animation-duration: 3s;
  transition: all ease-in-out;
}

.p_dropIn {
  animation-name: dropIn;
  animation-duration: 3s;
  transition: all ease-in-out;
}

.p_flowRight {
  animation-name: flowRight;
  animation-duration: 3s;
  transition: all ease-in;
}

.feat-prod {
  /* text-align: center; */
  color: var(--primary) !important;
}

.max-width {
  max-width: 900px;
}

.hero_cont {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 2.5rem;
}

.hero_text {
  flex: 2;
  min-width: 300px;
  max-width: 700px;
}

.hero_img {
  display: none;
}

.div_flex {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  /* max-width: 350px; */
  width: 100%;
  background: rgb(245, 245, 245);
  padding: 1.5rem;
  margin-bottom: 1rem;
}

.center_spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}

.nav_profile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  gap: 0.75rem;
}

.logo_img {
  height: 2.5rem;
  padding-right: 0.5rem;
}

.logo_img1 {
  height: 5rem;
  position: absolute;
  bottom: 0;
  left: -10px;
  opacity: 0.2;
  z-index: -1;
}

.logo_img2 {
  height: 6rem;
  position: absolute;
  bottom: 0;
  left: 0;
  opacity: 0.2;
  z-index: -1;
}

.nav-link {
  color: var(--white) !important;
}

.table {
  width: 100% !important;
}

/* .lg-prof {
	display: none !important;
} */

.sm-prof {
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
}

main {
  flex: 1;
  margin: 5rem 1rem 2rem;
}

.ref_bod {
  background: rgba(253, 255, 253, 0.925);
  border: 1px solid rgb(136, 136, 136);
  padding: 10px;
  border-radius: 8px;
  margin-bottom: 1rem;
  max-width: 450px;
  width: 100%;
}

/* Products */
.products {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  /* background: rgb(69, 216, 69); */
}

.product {
  /* border: 3px solid rgb(221, 217, 217); */
  display: flex;
  flex-direction: column;
  min-width: 250px;
  max-width: 300px;
  border-radius: 20px;
  background: rgba(228, 226, 226, 0.705);
  margin: 1.25rem auto;
  padding: 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  transition: all cubic-bezier(0, 2.95, 0.75, 0.64) 00ms;
}

.p-name {
  font-size: 1.5rem;
  font-weight: bold;
  color: #02552b;
  padding-top: 10px;
}

.p-price {
  font-size: 1.5rem;
  font-weight: bold;
  padding-top: 5px;
  color: #e8751a;
}

.product:hover {
  scale: 1.05;
  background: rgba(219, 217, 217, 0.959);
}

.product img {
  background: white;
  width: 100%;
  height: 255px;
  object-fit: cover;
  border-radius: 20px 20px 0 0;
}

.product-info {
  position: relative;
}

.text2 {
  font-size: 1.2rem;
  font-weight: bold;
}
/* .products {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 3rem;
}

.product {
  border: 1px solid #404040;
  border-radius: 4px;
  margin: 0.5rem 1rem;
} */

/* .prod_card {
  transition: all cubic-bezier(0, -1.04, 1, 2.86) 0.5s;
}

.prod_card:hover {
  transform: scale(1.01);
}

.product img {
  width: 100%;
  max-width: 250px;
} 

.product-info {
  padding: 1rem;
} 

.rating span {
  color: var(--primary);
}

.rating {
  text-align: left;
}

.i-color {
  color: var(--white);
} */

.pro-but {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background-color: #12372a;
  color: whitesmoke;
  transition: all ease-in-out 400ms;
  position: absolute;
  bottom: 2px;
  right: 0px;
  outline: none;
  border: none;
  font-weight: bold;
}

.pro-but-1 {
  padding: 0.3rem 1.5rem;
  border-radius: 4px;
  background-color: #12372a;
  color: whitesmoke;
  transition: all ease-in-out 400ms;
  outline: none;
  border: none;
  font-weight: bold;
  margin: 10px auto;
  min-width: 100%;
}

.pro-but-0 {
  padding: 0.5rem 1rem;
  border-radius: 4px;
  background-color: rgba(194, 193, 193, 0.75);
  color: rgb(135, 135, 135);
  transition: all ease-in-out 400ms;
  position: absolute;
  bottom: 2px;
  right: 0px;
  outline: none;
  border: none;
}

.pro-but:hover,
.pro-but-1:hover {
  background: #fbfada;
  border: none;
  color: #12372a;
}
.rating {
  margin-top: -10px;
}

.rating span {
  color: #436850;
  font-weight: bold;
}

.align-c {
  margin: 0 auto;
}

footer {
  display: flex;
  justify-content: center;
}

.img-large {
  width: 100%;
  /* max-width: 100%; */
}

.p_rel {
  position: relative;
}

.p_abs {
  position: absolute;
  right: 10px;
}

/* .x30 {
  width: 30px;
  height: 30px;
} */

.mt10 {
  margin-top: 4rem;
}

.mt11 {
  margin-bottom: 1rem;
}

.mt11 {
  margin-top: 4rem;
}

.mt2 {
  margin-top: 10px;
}

.mb1 {
  margin-bottom: 1rem;
}

.mxa {
  max-width: 30px;
  margin: 0 auto;
}

.loading_cont {
  max-width: 30px;
  margin: 0 auto;
}

.me-auto {
  margin-left: auto;
}

.img-thumbnail {
  height: 80px;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.s-text {
  font-size: 1rem;
  text-decoration: underline;
  color: var(--primary_hover);
  margin-left: 5px;
}

.s-text:hover {
  color: var(--primary);
}

.checkout-steps > div {
  border-bottom: 0.2rem solid #a0a0a0;
  color: #a0a0a0;
}

.checkout-steps > div.active {
  border-bottom: 0.2rem solid #f08000;
  color: #f08000;
}

.bg-red {
  background: red;
}

.spaceFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 0;
  padding: 0;
}

.mw-350 {
  max-width: 350px;
}

.iFlex {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}

.iFlex1 {
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 1rem;
}

.spacing0 {
  margin: 0;
  padding: 0;
}

.circle {
  width: 25px;
  margin-right: auto;
}

#text--color {
  color: #12372a !important;
}

.custom-control-input:focus ~ .custom-control-label::before {
  box-shadow: none !important;
}

.custom-control-input:focus ~ .custom-control-label::after {
  box-shadow: none !important;
}

input[type='radio']:focus {
  outline: none !important;
  box-shadow: none !important;
}

input[type='radio']:after {
  width: 16px;
  height: 16px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: var(--white);
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid var(--primary_hover);
}

input[type='radio']:checked:after {
  width: 16px;
  height: 16px;
  border-radius: 15px;
  top: -2px;
  left: -1px;
  position: relative;
  background-color: var(--primary_hover);
  content: '';
  display: inline-block;
  visibility: visible;
  border: 2px solid var(--primary);
}

.sm-font {
  font-size: 1.25rem;
}

element.style {
  position: absolute;
  inset: 0px auto auto -70px;
  transform: translate(0px, 26px);
}

.ref_link {
  font-size: 0.85rem;
  padding: 3px 6px;
  background: rgba(253, 255, 253, 0.925);
  border-radius: 4px;
  width: 100%;
}

/* @media only screen and (min-width: 765px) { */
@media only screen and (min-width: 768px) {
  body {
    font-family: 'Source Sans 3', sans-serif;
    background: green;
    background: radial-gradient(
      circle,
      rgb(192, 241, 204) 0%,
      rgb(216, 239, 219) 0%,
      rgb(221, 236, 229) 100%
    );
  }

  .div_none {
    display: block;
  }

  .sm-prof {
    display: none !important;
  }

  .lg-prof {
    display: flex;
    flex-direction: row;
    gap: 0.75rem;
  }

  .sm_scr {
    display: none !important;
  }

  .lg_scr {
    display: block !important;
    width: 100%;
  }

  .iFlex1 {
    justify-content: space-between;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .pos_tr {
    top: 0;
    right: 0;
  }

  .nav_logo {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 20px;
    position: relative;
  }
  .mt2 {
    margin-top: 0;
  }

  .circle {
    width: 25px;
    margin-right: auto;
  }

  .logo_text {
    font-size: 2rem;

    font-weight: 700;
    margin: 0;
  }

  .logo_img {
    height: 4rem;
    /* padding-right: 0.5rem; */
  }

  .nav_profile {
    flex-direction: row;
    gap: 0.75rem;
  }

  .red1 {
    font-size: 14px;
    color: red;
    margin: 10px 0;
  }

  .hero_cont {
    padding: 0;
    margin: 0 0 1.5rem;
  }

  .hero_img {
    flex: 1;
    min-width: 300px;
    max-width: 450px;
    display: inline-block;
  }

  .hero_img img {
    margin: 0 auto;
    text-align: center;
  }

  .hero_img1 {
    max-width: 350px;
    overflow: hidden;
  }

  h1 {
    font-size: 2rem;
    margin: 0.5rem 0 1.5rem;
  }

  .text-center {
    text-align: left !important;
  }

  .textGray {
    color: var(--gray);
    font-size: 18px;
  }

  header {
    padding: var(--padding-md_nav);
  }

  header a {
    font-size: 2rem;
    font-weight: 700;
  }

  header img {
    height: 2rem;
  }

  main {
    margin: var(--padding-md);
  }

  /* Sidebar */

  .dis_none {
    display: block;
  }

  .dis0 {
    display: none;
  }

  .dis_flex {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .navbar-brand {
    font-weight: bold;
  }

  .side-navbar {
    width: 240px;
    height: 100%;
    position: absolute;
    left: -300px;
    border-color: #100901;
    transition: 0.5s;
    background: var(--primary_hover1);
  }

  .products {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    /* padding: 1rem; */
  }

  .site-container {
    transition: 0.4s;
  }

  .active-cont {
    margin-left: 240px;
  }

  .active-nav {
    left: 0;
  }
}

@media only screen and (min-width: 990px) {
  main {
    margin: var(--padding-xl);
  }

  header {
    padding: var(--padding-lg);
  }
}
